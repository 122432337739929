import { render, staticRenderFns } from "./DemoRadioValidation.vue?vue&type=template&id=9f2988c2"
import script from "./DemoRadioValidation.vue?vue&type=script&lang=js"
export * from "./DemoRadioValidation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports